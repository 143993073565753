import axios from 'axios';
import { LANGUAGE_STORAGE_KEY } from '../constants';

export function createRequest({ apiBaseUrl, apiKey, language = 'sv'}) {
  if(!apiBaseUrl) {
    throw new Error('apiBaseUrl argument is necessary')
  }
  
  if(!apiKey) {
    throw new Error('apiKey argument is necessary')
  }

  /**
 * Create an Axios Client with defaults
 */
  const client = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      'x-language': language,
    },
  });

  client.interceptors.request.use((config) => {
    if(typeof localStorage !== 'undefined' && localStorage.getItem(LANGUAGE_STORAGE_KEY) ) {
      config.headers['x-language'] = localStorage.getItem(LANGUAGE_STORAGE_KEY);
    }
    return config;
  })

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  /**
   * Request Wrapper with default success/error actions
   */
  const request = function (options) {
    const headers = {};

    return client(options);
  };

  return request;
}

const request = createRequest({ apiBaseUrl: import.meta.env.VITE_API_BASE_URL, apiKey: import.meta.env.VITE_API_KEY, language: import.meta.env.LANGUAGE })

export default request;
